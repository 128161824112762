import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'

export default () => (
    <Layout>
        <h1>Szkolenia</h1>
        <p>Chcesz nauczyć się czegoś nowego na temat testowania oprogramowania lub automatyzacji testów? Chcesz poznać najlepsze narzędzia do testowania? Potrzebujesz szkolenia dla swojego zespołu?</p>
        <p>Zapoznaj się z ofertą prowadzonych przeze mnie szkoleń:</p>
        <StyledHeader>Testowanie API z pomocą SOAP UI</StyledHeader>
        <p>Prowadzone w postaci 3-dniowych, praktycznych warsztatów u klienta lub zdalnie</p>
        <p>Kurs online - 12 modułów</p>
        <LinkToLanding href='https://kurs.soapui.online/'>Dowiedz się więcej.</LinkToLanding>
        <StyledHeader>Automatyzacja testów End-to-End z użyciem Serenity BDD</StyledHeader>
        <p>Prowadzone w postaci 2-dniowych, praktycznych warsztatów u klienta lub zdalnie</p>
        <StyledHeader>Automatyzacja testów API za pomocą Postmana</StyledHeader>
        <p>Prowadzone w postaci 2-dniowych, praktycznych warsztatów u klienta lub zdalnie</p>
        <StyledHeader>Automatyzacja testów API/UI w JavaScript</StyledHeader>
        <p>Prowadzone w postaci 2-dniowych, praktycznych warsztatów u klienta lub zdalnie</p>
        <CTA>Szkolenia nie ma na liście? Napisz do mnie - ustalimy termin i przygotuję szkolenie na interesujący Cię temat.</CTA>
    </Layout>
)

const StyledHeader = styled.h3`
    color: #CC5803;
`

const CTA = styled.p`
    margin-top: 1rem;
`

const LinkToLanding = styled.a`
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    color: #CC5803;
    margin-bottom: 3rem;
`